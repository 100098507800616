import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

const CookieBanner = () => {
    const {t} = useTranslation();


    const [bannerCollapsed, setBannerCollapsed] = useState(false);

    return (
        !bannerCollapsed ?
            <div className="cookie fixed-bottom">

                <div className="content">

                    <div className="text">{t("cookie-banner-text")} <a className="text-more"
                                                                       href={process.env.PUBLIC_URL + "#/privacy"}>{t("cookie-banner-read-more")}</a>
                    </div>

                    <div className="div-button">
                        <button className="btn btn-outline button-text"
                                onClick={() => setBannerCollapsed(true)}>{t("cookie-banner-button-text")}
                        </button>
                    </div>
                </div>

            </div> : <div/>
    )
}


export default CookieBanner