import React from "react";
import {useTranslation} from "react-i18next";

const Terms = () => {
    const {t} = useTranslation();
    return (
        <section>
            <div className="terms">
                <h3>{t("terms-of-use")}</h3> <br/>
                <h4>{t("terms-agreement-headline")}</h4>
                <p>{t("terms-agreement-text")}</p>
                <h4>{t("terms-account-headline")}</h4>
                <p>{t("terms-account-text")}</p>
                <h4>{t("terms-modification-headline")} </h4>
                <p>{t("terms-modification-text")}</p>
                <h4>{t("terms-security-headline")} </h4>
                <p>{t("terms-security-text")}</p>
                <h4>{t("terms-rights-headline")} </h4>
                <p>{t("terms-rights-text")}</p>
                <h4>{t("terms-community-rules-headline")} </h4>
                <p>{t("terms-community-rules-text")}</p>
                <h4>{t("terms-contents-headline")} </h4>
                <p>{t("terms-contents-text")}</p>
                <h4>{t("terms-third-party-headline")} </h4>
                <p>{t("terms-third-party-text")}</p>
            </div>
        </section>
    )
};

export default Terms;