import * as firebase from 'firebase';

export const firebaseConfig = {
    apiKey: "AIzaSyDBs6fE4yW8xzEBVNoRiD2-eRfudMAB_XM",
    authDomain: "stansday-web.firebaseapp.com",
    databaseURL: "https://stansday-web.firebaseio.com",
    projectId: "stansday-web",
    storageBucket: "stansday-web.appspot.com",
    messagingSenderId: "276765919866",
    appId: "1:276765919866:web:9bda1a750984828c7e49c3",
    measurementId: "G-34B8MZKQPW"
};

firebase.initializeApp(firebaseConfig)

// workaround because analytics will crash in firefox private mode
// firefox private mode is not allowed to create indexdDB
// https://github.com/firebase/firebase-js-sdk/issues/801
export let firebaseAnalytics = new Promise( (resolve, reject) =>  {
    const db = indexedDB.open("foo")
    db.onsuccess = () => {
        resolve(firebase.analytics());
    };
    db.onerror = () => {
        const analyticsMock = {
            logEvent: () => {},
            setCurrentScreen: () => {},
            setUserId: () => {},
        };
        resolve(analyticsMock);
    };
});