import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from '../locales/de/translation'
import en from '../locales/en/translation'
import {initReactI18next} from 'react-i18next';

const resources = {
    en: {
        translation: en
    },
    de: {
        translation: de
    }
};


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',

        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;