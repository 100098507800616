import React, {useEffect} from 'react';
import Stefan from "../img/stefan_jung.png";
import {useTranslation} from "react-i18next";

const Contact = () => {
    const {t} = useTranslation();



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (

        <div className="contact" style={{marginTop: "5%", height: "100vh"}}>
            <div className="container">

                <img src={Stefan} width="350" className="image mx-auto d-block" alt=""/>
                <div className="mx-auto" style={{marginTop: "5%", textAlign: 'center'}}>

                    <p>{t("contact_aboutme")}</p>
                    <p>{t("contact_questions")}</p>
                    <p>{t("contact_email")}</p>


                </div>
            </div>


        </div>
    )

};

export default Contact