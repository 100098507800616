import React from 'react';
import Logo from '../img/stansday-small-300.png';
import Having_Fun from "../img/having_fun.svg";
import Komoot_Logo from "../img/komoot_logo.png";

export default class Komoot extends React.Component{
    render(){
        return(
            <div className="komoot">
                <div className="text-center">
                    <img src={Logo} width="300" alt=""/>
                    <p id="textCong" className="text">Congratulation!</p>

                    <img src={Having_Fun} width="350" className="image mx-auto d-block" alt=""/>

                    <img src={Komoot_Logo} width="300" className="image" alt=""/>
                        <p className="text">is successfully connected to your account</p>

                </div>
            </div>
        )
    }
}