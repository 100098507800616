import React from 'react';

export default class Privacy extends React.Component {


    componentDidMount() {

    }

    render() {
        window.scrollTo(0, 0);
        return (

            <div className="privacy"><br/>
                <h1>Disclaimer – rechtliche Hinweise</h1>
                <p>
                    <strong>1. Haftungsbeschränkung</strong><br/>
                    Die Inhalte des Internetauftritts wurden mit größtmöglicher Sorgfalt und nach bestem Gewissen
                    erstellt. Dennoch übernimmt der Anbieter dieser Webseite keine Gewähr für die Aktualität,
                    Vollständigkeit und Richtigkeit der bereitgestellten Seiten und Inhalte.</p>
                <p>Als Diensteanbieter ist der Anbieter dieser Webseite gemäß § 7 Abs. 1 TMG für eigene Inhalte und
                    bereitgestellte Informationen auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich; nach
                    den §§ 8 bis 10 TMG jedoch nicht verpflichtet, die übermittelten oder gespeicherten fremden
                    Informationen zu überwachen. Eine Entfernung oder Sperrung dieser Inhalte erfolgt umgehend ab dem
                    Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung. Eine Haftung ist erst ab dem Zeitpunkt der
                    Kenntniserlangung möglich.</p>
                <p><strong>2. Externe Links</strong><br/>
                    Die Webseite enthält sog. „externe Links“ (Verlinkungen) zu anderen Webseiten, auf deren Inhalt der
                    Anbieter der Webseite keinen Einfluss hat. Aus diesem Grund kann der Anbieter für diese Inhalte auch
                    keine Gewähr übernehmen.<br/>
                    Für die Inhalte und Richtigkeit der bereitgestellten Informationen ist der jeweilige Anbieter der
                    verlinkten Webseite verantwortlich. Zum Zeitpunkt der Verlinkung waren keine Rechtsverstöße
                    erkennbar. Bei Bekanntwerden einer solchen Rechtsverletzung wird der Link umgehend entfernen. </p>
                <p><strong>3. Urheberrecht/Leistungsschutzrecht</strong><br/>
                    Die auf dieser Webseite veröffentlichten Inhalte, Werke und bereitgestellten Informationen
                    unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Jede Art der Vervielfältigung,
                    Bearbeitung, Verbreitung, Einspeicherung und jede Art der Verwertung außerhalb der Grenzen des
                    Urheberrechts bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Rechteinhabers. Das
                    unerlaubte Kopieren/Speichern der bereitgestellten Informationen auf diesen Webseiten ist nicht
                    gestattet und strafbar. </p>
                <p><strong>4. Datenschutz</strong><br/>
                    Durch den Besuch des Internetauftritts können Informationen (Datum, Uhrzeit, aufgerufene Seite) über
                    den Zugriff auf dem Server gespeichert werden. Es werden keine personenbezogenenen (z. B. Name,
                    Anschrift oder E-Mail-Adresse) Daten, gespeichert.</p>
                <p>Sofern personenbezogene Daten erhoben werden, erfolgt dies, sofern möglich, nur mit dem vorherigen
                    Einverständnis des Nutzers der Webseite. Eine Weitergabe der Daten an Dritte findet ohne
                    ausdrückliche Zustimmung des Nutzers nicht statt.</p>
                <p>Der Anbieter weist darauf hin, dass die Übertragung von Daten im Internet (z. B. per E-Mail)
                    Sicherheitslücken aufweisen und ein lückenloser Schutz der Daten vor dem Zugriff Dritter nicht
                    gewährleistet werden kann. Der Anbieter übernimmt keine Haftung für die durch solche
                    Sicherheitslücken entstandenen Schäden. </p>
                <p>Der Verwendung der Kontaktdaten durch Dritte zur gewerblichen Nutzung wird ausdrücklich
                    widersprochen. Es sei denn, der Anbieter hat zuvor seine schriftliche Einwilligung erteilt.<br/>
                    Der Anbieter behält sich rechtliche Schritte für den Fall der unverlangten Zusendung von
                    Werbeinformationen, z. B. durch Spam-Mails, vor.<br/>

                    <br/>

                    <h2>Google Analytics</h2>
                    <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google
                        Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
                    </p>
                    <p>Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien, die auf Ihrem Computer
                        gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
                        durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel
                        an einen Server von Google in den USA übertragen und dort gespeichert.
                    </p>
                    <p>Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf
                        Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
                        der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu
                        optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
                        Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs.
                        1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
                    </p>
                    <strong>Quelle</strong>: <a href="http://www.mustervorlage.net/disclaimer-muster/">Muster
                        Disclaimer</a>
                </p>
            </div>

        )
    }
}
