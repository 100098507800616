import React, {useState} from 'react';
import Logo from '../img/stansday-small-300.png'
import {Link} from "react-scroll";
import {useTranslation} from "react-i18next";


const Navigation = () => {
    const {t} = useTranslation();

    const [navCollapsed, setNavCollapsed] = useState(true);



        return (
            <nav className="navbar navbar-expand-lg  fixed-top" id="mainNav">
                <div className="container">
                    <a href={process.env.PUBLIC_URL + "/"}>
                        <img src={Logo} width="150" alt=""/>
                    </a>
                    <button className="navbar-toggler navbar-toggler-right"
                            type="button" data-toggle="collapse"
                            data-target="#navbarResponsive"
                            aria-controls="navbarResponsive"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => setNavCollapsed(!navCollapsed)}>
                        <i className="fa fa-bars"></i>
                    </button>
                    <div className={(navCollapsed ? 'collapse' : '') + ' navbar-collapse'} id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link isDynamic={true} spy={true} smooth={true} duration={500} to="download" href={""}
                                      className="nav-link js-scroll-trigger">
                                    {t("navbar-download")}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link isDynamic={true} spy={true} smooth={true} duration={500} to="features" href={""}
                                      className="nav-link js-scroll-trigger">
                                    {t("navbar-features")}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href={process.env.PUBLIC_URL + "#/contact"}>{t("navbar-contact")}</a>
                            </li>
                            <li className="nav-item">
                                <Link isDynamic={true} spy={true} smooth={true} duration={500} to="social-media" href={""}
                                      className="nav-link js-scroll-trigger">
                                    {t("navbar-social-media")}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
}

export default Navigation