import React from "react";
import {useTranslation} from "react-i18next";

const AppPrivacy = () => {
    const {t} = useTranslation();
    return (
        <section>
            <div className="app-privacy">
                <h3>{t("privacy")}</h3>
                <h4>{t("privacy-general-headline")}</h4>
                <p>{t("privacy-general-text")} </p>

                <h3>{t("privacy-data-headline")}</h3>
                <h4>{t("privacy-registration-headline")}</h4>
                <p>{t("privacy-registration-text")}</p>
                <h4>{t("privacy-app-usage-data-headline")}</h4>
                <p>{t("privacy-app-usage-data-text")}</p>
                <h4>{t("privacy-comment-headline")}</h4>
                <p>{t("privacy-comment-text")}</p>
                <h4>{t("privacy-external-links-headline")}</h4>
                <p>{t("privacy-external-links-text")}
                </p>
                <h4>{t("privacy-google-web-fonts-headline")}</h4>
                <p>{t("privacy-google-web-fonts-text")}
                </p>

                <h4>{t("privacy-google-maps-headline")}</h4>
                <p>{t("privacy-google-maps-text")}
                </p>
                <h4>{t("privacy-external-hosting-headline")}</h4>
                <p>{t("privacy-external-hosting-text")}</p>
            </div>
        </section>
    )
}

export default AppPrivacy;

