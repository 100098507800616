import React, {useState} from 'react';
import axios from 'axios';
import Logo from "../img/stansday-small-300.png";
import {useTranslation} from "react-i18next";

const ResendConfirmation = () => {
    const {t} = useTranslation();

    const [email, setEmail] = useState(null)


    const _submit = () => {
        axios
            .post(`https://api.stansday.com/auth/send-email-confirmation`, {
                email: email, // user's email
            })
            .then(response => {
                alert(t("resend-confirmation-success"))
            })
            .catch(error => {
                if(error.response.data.message === "already.confirmed"){
                    alert(t("resend-confirmation-already-done"))
                } else {
                    alert(t("resend-confirmation-not-found"))
                }
            });
    }

    return (
        <div className="password">
            <form className="form-password" id="forgot-password">
                <div className="text-center mb-4">
                    <img className="mb-4" src={Logo} width="300" alt=""/>

                    <p className="text-light">{t("resend-confirmation-text")}</p>
                </div>

                <div className="form-label-group">
                    <input id="inputPassword" className="form-control" placeholder="Email"
                           required onChange={(e) => setEmail(e.target.value)}/>
                    <label>Email</label>
                </div>


                <button className="btn btn-lg btn-secondary btn-block"
                        onClick={(e) => _submit(e)}>{t("resend-confirmation-button")}
                </button>
                <p className="mt-5 mb-3 text-light text-center">&copy; Stansday 2021</p>
            </form>
        </div>
    )

};

export default ResendConfirmation