import React from 'react';
import Logo from '../img/stansday-small-300.png';
import Having_Fun from "../img/having_fun.svg";
import {useTranslation} from "react-i18next";

const Welcome = () => {
    const {t} = useTranslation();

        return(
            <div className="welcome">
                <div className="text-center">
                    <img src={Logo} width="300" alt=""/>
                    <p className="text">{t("welcome-text")}</p>

                    <img src={Having_Fun} width="350" className="image mx-auto d-block" alt=""/>


                    <p className="text">{t("welcome-account-confirmed")}</p>

                </div>
            </div>
        )

};

export default Welcome