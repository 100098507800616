import React from 'react';
import Party from "../img/party.png";
import Modal from 'react-modal';


const closeButton = <svg width={window.innerWidth > 768 ? "1.5em": "1.2em"} height={window.innerWidth > 768 ? "1.5em": "1.2em"} viewBox="0 0 16 16" className="bi bi-x-circle" fill="lightgrey"
                         xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path fill-rule="evenodd"
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>

const customStyles = {
    content: {
        height: '100%',
        width: '50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: "transparent",
        borderWidth: 0,
        marginTop: '10%'
    }
};
const phoneStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: "transparent",
        borderWidth: 0
    }
}


class AvailableSoon extends React.Component {
    render() {
        return (

            <Modal
                isOpen={this.props.isOpen}
                contentLabel="Example Modal"
                style={window.innerWidth > 768 ? customStyles : phoneStyle}
            >
                <div className='popup'>
                    <div className='popup_inner'>
                        <div className="popup_center">
                            <div className={"popup_button_close"} onClick={this.props.closePopup}> {closeButton} </div>
                            <h1 className={'popup_headline'}>{this.props.headline}</h1>
                            <img className='popup_image' src={Party} alt=""/>
                            <h3 className={'popup_text'}>{this.props.text}</h3>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AvailableSoon;