import React from 'react';

export default class SheltoPrivacy extends React.Component {


    componentDidMount() {

    }

    render() {
        window.scrollTo(0, 0);
        return (

            <div className="privacy">
    <h1>Datenschutzerklärung für Shelto</h1>

    <h2>1. Einleitung</h2>
    <p>
        Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. In
        dieser Datenschutzerklärung informieren wir Sie darüber, wie wir Ihre
        personenbezogenen Daten in unserer App verarbeiten und welche Rechte
        Sie in Bezug auf Ihre Daten haben.
    </p>

    <h2>2. Verantwortliche Stelle</h2>
    <p>Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist:</p>
    <p>
        Stansday UG (haftungsbeschränkt)
        <br />
        Oberer Kolberg 12, 34212 Melsungen, Deutschland
        <br />
        info@stansday.com
    </p>

    <h2>3. Erhebung und Verarbeitung personenbezogener Daten</h2>
    <p>
        Wenn Sie unsere App nutzen, erheben und verarbeiten wir die folgenden
        personenbezogenen Daten:
    </p>
    <ul>
        <li>
            <strong>Nutzungsdaten:</strong> Informationen über Ihre
            Interaktionen mit der App, z.B. welche Funktionen Sie verwenden,
            Ihre Standortdaten (wenn aktiviert) und Ihre Präferenzen.
        </li>
        <li>
            <strong>Kontodaten:</strong> Wenn Sie ein Konto erstellen, erfassen
            wir Ihre E-Mail-Adresse und gegebenenfalls andere Informationen wie
            Ihren Benutzernamen.
        </li>
        <li>
            <strong>Inhaltsdaten:</strong> Inhalte, die Sie in der App
            hochladen, wie z.B. Fotos, Bewertungen oder Kommentare.
        </li>
        <li>
            <strong>Standortdaten:</strong> Wenn Sie die Standortdienste in der
            App aktivieren, erfassen wir Ihren Standort, um Funktionen wie das
            Anzeigen von nahegelegenen Orten zu ermöglichen.
        </li>
    </ul>

    <h2>4. Zweck und Rechtsgrundlage der Verarbeitung</h2>
    <p>Wir verarbeiten Ihre Daten zu folgenden Zwecken:</p>
    <ul>
        <li>
            <strong>Nutzungsoptimierung:</strong> Um die App zu verbessern und
            Ihnen personalisierte Inhalte und Empfehlungen anzuzeigen.
        </li>
        <li>
            <strong>Erfüllung von Verträgen:</strong> Wenn Sie ein Nutzerkonto
            erstellen oder Funktionen der App nutzen, verarbeiten wir Ihre
            Daten zur Vertragserfüllung.
        </li>
        <li>
            <strong>Berechtigte Interessen:</strong> Wir verarbeiten Ihre Daten
            auch, um technische Probleme zu beheben, die Sicherheit zu
            gewährleisten und missbräuchliche Nutzung zu verhindern.
        </li>
        <li>
            <strong>Einwilligung:</strong> In einigen Fällen verarbeiten wir
            Ihre Daten basierend auf Ihrer ausdrücklichen Einwilligung, z.B. bei
            der Verwendung von Standortdaten.
        </li>
    </ul>

    <h2>5. Datenweitergabe an Dritte</h2>
    <p>Wir geben Ihre personenbezogenen Daten nur an Dritte weiter, wenn:</p>
    <ul>
        <li>Sie ausdrücklich eingewilligt haben,</li>
        <li>
            dies zur Erfüllung eines Vertrags erforderlich ist (z.B. bei der
            Nutzung von externen Zahlungsanbietern),
        </li>
        <li>wir gesetzlich dazu verpflichtet sind oder</li>
        <li>
            berechtigte Interessen unsererseits oder eines Dritten dies
            erfordern (z.B. zur Durchsetzung von Rechtsansprüchen).
        </li>
    </ul>

    <h2>6. Speicherdauer</h2>
    <p>
        Ihre personenbezogenen Daten werden nur so lange gespeichert, wie es
        für die Erfüllung der genannten Zwecke erforderlich ist oder wir
        gesetzlich zur Aufbewahrung verpflichtet sind.
    </p>

    <h2>7. Ihre Rechte</h2>
    <p>Sie haben folgende Rechte in Bezug auf Ihre personenbezogenen Daten:</p>
    <ul>
        <li>
            <strong>Recht auf Auskunft:</strong> Sie können jederzeit eine
            Bestätigung darüber verlangen, ob personenbezogene Daten verarbeitet
            werden und Auskunft über diese Daten erhalten.
        </li>
        <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben das Recht,
            unrichtige Daten berichtigen zu lassen.
        </li>
        <li>
            <strong>Recht auf Löschung:</strong> Sie können die Löschung Ihrer
            personenbezogenen Daten verlangen, sofern keine rechtlichen
            Aufbewahrungspflichten bestehen.
        </li>
        <li>
            <strong>Recht auf Einschränkung der Verarbeitung:</strong> Sie
            können die Einschränkung der Verarbeitung Ihrer Daten verlangen.
        </li>
        <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
            Recht, die Sie betreffenden Daten in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten.
        </li>
        <li>
            <strong>Widerspruchsrecht:</strong> Sie können der Verarbeitung
            Ihrer Daten jederzeit widersprechen, wenn diese auf einem
            berechtigten Interesse basiert.
        </li>
        <li>
            <strong>Widerrufsrecht bei Einwilligung:</strong> Wenn die
            Verarbeitung auf Ihrer Einwilligung basiert, können Sie diese
            jederzeit widerrufen.
        </li>
    </ul>

    <h2>8. Datensicherheit</h2>
    <p>
        Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um
        Ihre Daten vor unberechtigtem Zugriff, Verlust oder Zerstörung zu
        schützen.
    </p>

    <h2>9. Änderungen der Datenschutzerklärung</h2>
    <p>
        Wir behalten uns vor, diese Datenschutzerklärung gelegentlich zu
        aktualisieren, um sie an geänderte rechtliche Rahmenbedingungen oder
        neue Funktionen in der App anzupassen. Die jeweils aktuelle Version ist
        in der App einsehbar.
    </p>

    <h2>10. Kontakt</h2>
    <p>
        Wenn Sie Fragen zur Verarbeitung Ihrer personenbezogenen Daten oder zu
        dieser Datenschutzerklärung haben, können Sie uns unter den oben
        genannten Kontaktdaten erreichen.
    </p>
</div>

        )
    }
}
