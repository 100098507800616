import React, {useState} from 'react';
import GooglePlay from '../img/google-play-badge.svg';
import AppStore from '../img/app-store-badge.svg';
import {firebaseAnalytics} from "../firebase/firebase-init";
import {useTranslation} from "react-i18next";
import AvailableSoon from "./AvailableSoon";

const Download = () => {
    const {t} = useTranslation();

    const [showPopup, setShowPopup] = useState(false);


    return (
        <section className="download bg-secondary text-center" id="download">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <h2 className="section-heading">{t("download-headline")}</h2>
                        <p>{t("download-underline")}</p>
                        <div className="badges">
                            <div className="badge-link">
                                <a href={"https://play.google.com/store/apps/details?id=com.stansday.app"} >
                                <img src={GooglePlay} alt=""
                                     onClick={
                                         () => {
                                             firebaseAnalytics.then((analytics) => {
                                                 analytics.logEvent("download_android")
                                             });
                                         }}/>
                                </a>
                            </div>
                            <div className="badge-link">
                                <a href={"https://apps.apple.com/de/app/stansday/id1539169967"} >
                                <img src={AppStore} alt=""
                                     onClick={
                                         () => {
                                             firebaseAnalytics.then((analytics) => {
                                                 analytics.logEvent("download_ios")
                                             });
                                         }
                                     }/>
                                </a>
                            </div>

                            {showPopup && (
                                <AvailableSoon
                                    isOpen={showPopup}
                                    headline={t("available_soon_headline")}
                                    text={t("available_soon_text")}
                                    closePopup={() => setShowPopup(false)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Download